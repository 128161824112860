import React from 'react';
import { Row } from 'react-flexbox-grid';
import VideoCollectionItem from '../VideoCollectionItem';

// const OpenLink = (closeAction, openURL) => {
//   closeAction();
//   setTimeout(() => {
//     window.open(openURL);
//   }, 100);
// };

const VideoCollection = props => {
  const content = props.centered ? (
    <Row center="xs">
      {props.collection.map((item, index) => (
        <VideoCollectionItem
          item={item}
          key={index}
          video
          pageName={props.pageName}
        />
      ))}
    </Row>
  ) : (
    <Row>
      {props.collection.map((item, index) => (
        <VideoCollectionItem
          item={item}
          key={index}
          video={false}
          pageName={props.pageName}
        />
      ))}
    </Row>
  );

  return <div>{content}</div>;
};

export default VideoCollection;
