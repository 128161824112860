import React from 'react';
import { Col } from 'react-flexbox-grid';
import './video-collection-item.scss';
import ActiveLink from '../ActiveLink';
import myEmitter from '../../libs/emitter';
import { withPrefix } from 'gatsby-link';
import ExternalLink from '../ExternalLink';

class VideoCollectionItem extends React.Component {
  componentDidMount() {}

  onThumbClick = () => {
    myEmitter.emit('onPatientVideoClick', this.props.item);
    console.log('thumb click');
  };

  render() {
    const {
      thumb,
      title,
      copy,
      href,
      pdf,
      external,
      alt,
      pageName,
      wistiaID,
    } = this.props.item;
    const { video } = this.props;
    const image = require(`../../../assets/images/video-thumbnails/` + thumb);
    const resourceURL = pdf ? withPrefix(href) : href;

    console.log(resourceURL);
    const LinkComponent = external ? (
      <ExternalLink href={resourceURL} ExternalLink>
        <img src={image} alt={alt} title={alt} />
      </ExternalLink>
    ) : (
      <ActiveLink
        to={resourceURL}
        ExternalLink
        EventObject={{
          category: 'Downloads',
          action: 'Click',
          label: `${copy} - ${pageName}`,
        }}
      >
        <img src={image} alt={alt} title={alt} />
      </ActiveLink>
    );

    const content = !video ? (
      <Col xs={12} md={3} className="collection-item-content">
        {LinkComponent}
        <h6>{title}</h6>
        <p dangerouslySetInnerHTML={{ __html: copy }} />
      </Col>
    ) : (
      <Col xs={12} md={3} className="collection-item-content">
        <a href={`#wistia_${wistiaID}`}>
          <img src={image} alt={alt} title={alt} />
        </a>
        <h6>{title}</h6>
        <p dangerouslySetInnerHTML={{ __html: copy }} />
      </Col>
    );
    return <React.Fragment>{content}</React.Fragment>;
  }
}

export default VideoCollectionItem;
