import React, { Component } from 'react';
import { pushEventGA } from '../../libs/utilityHelper';
import SourceEmitter from '../../libs/emitter';
import cx from 'classnames';

export default class ActiveLink extends Component {
  constructor(props) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.handleVidClick = this.handleVidClick.bind(this);
  }

  handleVidClick() {
    pushEventGA(this.props.EventObject);
    SourceEmitter.emit(`onVideoOpenEvent-${this.props.VideoId}`, {});
  }

  render() {
    const {
      DeadLink,
      children,
      ExternalLink,
      EnableActiveClass,
      EventObject,
      to,
      DownloadLink,
      VideoLink,
      Link,
    } = this.props;
    const className = cx({
      'yellow-button': false,
      [this.props.className]: this.props.className,
    });

    return (
      <div>
        {(() => {
          if (DeadLink) {
            return (
              <a href="javascript:void(0)" className={className}>
                {children}
              </a>
            );
          } else if (VideoLink) {
            return (
              <a
                href="javascript:void(0)"
                onClick={this.handleVidClick}
                className={className}
              >
                {children}
              </a>
            );
          } else if (Link) {
            return (
              <a
                href={Link}
                target="_blank"
                rel="noreferrer"
                onClick={() => pushEventGA(EventObject)}
                className={className}
              >
                {children}
              </a>
            );
          } else if (ExternalLink || ExternalLink === true || DownloadLink) {
            return (
              <a
                href={to}
                target="_blank"
                onClick={() => pushEventGA(EventObject)}
                className={className}
                rel="noreferrer"
              >
                {children}
              </a>
            );
          } else {
            return (
              <Link
                to={to}
                activeClassName={EnableActiveClass ? 'active' : ''}
                onClick={() => pushEventGA(EventObject)}
                className={className}
              >
                {children}
              </Link>
            );
          }
        })()}
      </div>
    );
  }
}
