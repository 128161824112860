// Note for later - to get info about a wistia video, use the URL
// https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/xxxxxxxx
// Where "xxxxxxxx" is the video's wistia ID
const videoCollection = [
  {
    thumb: 'turkey-piccata.png',
    title: `Turkey Piccata`,
    wistiaID: 'zr2p0gjvwb',
    iframeUrl: '//fast.wistia.net/embed/iframe/zr2p0gjvwb?videoFoam=true',
    alt: 'Turkey Piccata Recipe Video',
  },
  {
    thumb: 'fish-kebabs.png',
    title: `Grilled Fish Kebabs`,
    wistiaID: 'eanwrqurrt',
    iframeUrl: '//fast.wistia.net/embed/iframe/eanwrqurrt?videoFoam=true',
    alt: 'Grilled Fish Kebabs Recipe Video',
  },
  {
    thumb: 'frittata.png',
    title: `Farmers' Market Frittata`,
    wistiaID: 'et5w0tusdv',
    iframeUrl: '//fast.wistia.net/embed/iframe/et5w0tusdv?videoFoam=true',
    alt: "Farmers' Market Frittata Recipe Video",
  },
  {
    thumb: 'nutritionafterweightlosss.png',
    title: `Nutrition After Weight Loss Surgery`,
    wistiaID: 'jzbz9tj4os',
    iframeUrl: '//fast.wistia.net/embed/iframe/jzbz9tj4os?videoFoam=true',
    alt: 'Nutrition After Weight Loss Surgery',
  },
];

export default videoCollection;
