import React, { useContext, useState, useEffect } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor from 'react-scrollable-anchor';
// import windowSize from 'react-window-size';

// CUSTOM IMPORTS START
import { Layout, Seo } from 'components';
// import { appContext } from 'providers/appProvider';
import { appContext } from 'providers/appProvider';
import ContentBlock from 'components/Content/ContentBlock';``
import ColorBlock from 'components/Content/ColorBlock';
import WistiaVideo from 'components/WistiaVideo';
import VideoCollection from 'components/VideoCollection';
import NutritionDirectVideos from 'libs/videos/nutrition-direct-videos';

// CUSTOM IMPORTS END-->

// IMAGES START

// PDFs

// SASS
import './videos.scss';

// INITIALIZATIONS
// let Subscription = null;

// TRACKING
// const learnTracking = { category: "URL-Learn", action: "clicked on learn more", label: "Redirects to Bariactiv Supplements Section" };
// const enrollmentTracking = { category: "URL-Enroll", action: "clicked on URL-Enroll", label: "Redirects to Enrollment and FAQ Section" };

const Video = props => {
  const { appConfigs } = useContext(appContext);
  const [currentVideo, setCurrentVideo] = useState(NutritionDirectVideos[0]);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionPatient;
  // const metaData = appConfigs?.metaData?.nutritionPatient;
  // const [directLinkVideo, setDirectLinkVideo] = useState(false);
  // const { appConfigs } = useContext(appContext);
  // const title = appConfigs?.metaData?.title;

  /*  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  }; */

  // const clickVideoInLibrary = videoId => {
  //   console.log('selected video: ' + videoId);
  //   this.state.currentVideoUrl = videoId;
  // };

  const pageTitle = 'NUTRITION DIRECT™ | Video Library';
  const pageDescription =
    'Watch recipe videos and learn about Nutrition Direct™';

    const setResponseParams = () => {
      const responseObj = window.location.hash
        .substr(1)
        .split('&')
        .map((el) => el.split('='))
        .reduce((pre, cur) => {
          pre[cur[0]] = cur[1]
          return pre
        }, {})
      console.log(window.location.hash)
      console.log(`have params`)
      console.log(responseObj.video)

      if (responseObj.video) {
        const video = NutritionDirectVideos.find((video) => video.wistiaID === responseObj.video)
        console.log(video)
        setCurrentVideo(video)
      }
    }
  
    useEffect(() => {
      setResponseParams()
    }, [])

  return (
    <Layout indication={indication} className="nutrition-patient-videos">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          <div id="nutrition-patient-videos">
            <ContentBlock>
              <Grid
                fluid
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 0,
                }}
              >
                <Row>
                  <Col xs={12}>
                    <ContentBlock
                      id="enrollment-and-faqs-block"
                      className="margin-offset"
                    >
                      <Grid
                        fluid
                        style={{
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingTop: 0,
                        }}
                      >
                        <ColorBlock
                          white
                          id="colorblock-enrollment-forms-and-faqs"
                        >
                          <Row>
                            <Col xs={12}>
                              <h2 className="strong big-h2">VIDEO LIBRARY</h2>
                              {/* <p className="text-summary">
                                Nutrition Direct<sup>&trade;</sup> is serving up
                                support that goes beyond vitamins and minerals.
                                In addition to providing you with your monthly
                                supply of BariActiv
                                <sup>&reg;</sup> supplements&mdash;the
                                micronutrients you need after weight loss
                                (bariatric) surgery&mdash;we are now bringing
                                you easy and delicious, bariatric-friendly
                                recipes to make at home!
                              </p> */}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12}>
                              <div className="container-current-video">
                                <WistiaVideo
                                  wistiaID={currentVideo.wistiaID}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              <div className="container-video-library">
                                <VideoCollection
                                  centered
                                  video
                                  collection={NutritionDirectVideos}
                                  pageName="nutrition direct videos"
                                />
                              </div>
                            </Col>
                          </Row>
                        </ColorBlock>
                      </Grid>
                    </ContentBlock>
                  </Col>
                </Row>
              </Grid>
            </ContentBlock>
            <ScrollableAnchor id="isi_anchor">
              <div />
            </ScrollableAnchor>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Video;
